<template>
  <div>
    <div style="width:100%;text-align:center;margin-bottom:40px;margin-top:3%;">
      <img src="https://nexa.se/wp-content/uploads/2019/10/Nexa_logo_mail.png">
    </div>

    <div class="container">
      <div>
        <div style="margin-bottom:20px;">I sökfältet nedan kan du söka efter manualer i vårt register. Använd modellbeteckning för bästa resultat.</div>
        <input type="text" name="search" class="search" v-model="search" @keyup="didSearch" placeholder="Sök (modell)">

        <div style="margin-top:10px;">
          <div v-if="docs2.length">
              <h3>Visar {{docs2.length }} dokument</h3>

              <div v-for="doc in docs2" :key="doc.modell" style="margin-bottom:20px;">

                  <div style="border-left:1px solid #d2d2d2;padding:8px;background:#f5f5f5;border-radius:2px;font-weight:bold;"><router-link style="color:#000;border:0px;" :to="{ name: 'modell', params: { id: doc.modell.replace(/-/g, '') }}">{{ doc.modell.toUpperCase() }}</router-link></div>
                  <div style="padding-left:15px;margin-top:10px;">
                    <div class="categories">
                      <div v-for="(docs, column) in getColumns(doc.docs)" :key="column">
                        <strong style="margin-bottom:4px;">{{ dirToDescription[column] }}</strong>

                        <div v-for="manual in docs" :key="manual.hash">
                          <div><img v-if="getImage(manual.filename)" style="width:20px;height:20px;vertical-align:middle;margin-right:5px;" :src="getImage(manual.filename)"> <a target="_blank" @click="ping(manual.hash)" :href="getLink(manual.filename, manual.path)">{{ manual.filename }}</a></div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
          <div v-else>
            <div v-if="search.length">
              Åh nej, vi hittade tyvärr ingen manual som innehåller "{{ search }}".
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// const dotProp = require('dot-prop')

export default {
  name: 'Home',
  data () {
    return {
      docs: [],
      search: '',
      dirToDescription: {
        doc: 'Declaration of Conformity',
        manualer: 'Manualer'
      }
    }
  },
  async created () {
    try {
      // const res = await fetch('https://nexa.fra1.cdn.digitaloceanspaces.com/docs.json')
      const res = await fetch('https://api.nexabridge.se/manualer')
      let json = await res.json()
      json = json.filter(x => 'modell' in x && x.modell).sort((a, b) => a.modell.localeCompare(b.modell))

      for (const index in json) {
        const doc = json[index]

        if (!this.docs.find(x => x.modell === doc.modell)) {
          this.docs.push({
            modell: doc.modell,
            docs: []
          })
        }

        const index2 = this.docs.findIndex(x => x.modell === doc.modell)

        this.docs[index2].docs.push({
          filename: doc.filename,
          path: doc.path,
          dir: doc.type,
          hash: doc.id
        })
      }
    } catch (e) {
      this.docs = require('../docs.json')
    }

    // const params = new URLSearchParams(location.search)

    // if (params.has('q') && params.get('q').length) {
    //  this.search = params.get('q')
    // }

    if (this.$route.name === 'modell') {
      this.search = this.$route.params.id
    }
  },
  computed: {
    docs2 () {
      const strip = (string) => string.toLowerCase().replace(/ /g, '').replace(/-/g, '')

      if (this.search.length) {
        const search = strip(this.search)

        return this.docs.filter(x => ('modell' in x && x.modell)).filter(x => {
          if (strip(x.modell).includes(search)) return true
          // if (strip(x.produktgrupp).includes(search)) return true
          // if (x.docs.filter(x => strip(x.filename).includes(search)).length > 0) return true
          return false
        })
      }

      return []
    },
    categories () {
      return [
        { id: 'smartahem', label: 'Smarta Hem' },
        { id: 'tryggahem', label: 'Trygga Hem' },
        { id: 'proselect', label: 'Pro Select' }
      ]
    }
  },
  watch: {
    $route (to, from) {
      if (to.name === 'modell') this.search = to.params.id
    }
  },
  methods: {
    didSearch () {
      // const params = new URLSearchParams(location.search)
      // params.set('q', this.search)
      // window.history.replaceState({}, '', `${location.pathname}?${params}`)
    },

    getColumns (docs) {
      const out = {}

      for (const index in docs) {
        const doc = docs[index]

        if (!(doc.dir in out)) out[doc.dir] = []
        out[doc.dir].push({
          filename: doc.filename,
          path: doc.path,
          hash: doc.hash
        })
      }

      return out
    },

    getLink (filename, path) {
      return `https://nexa.fra1.cdn.digitaloceanspaces.com/${path}/${filename}`
    },

    getImage (name) {
      let image = ''
      name = name.toUpperCase()
      if (name.includes('SE') || name.includes('SV')) image = 'se.svg'
      if (name.includes('NO')) image = 'no.svg'
      if (name.includes('FI')) image = 'fi.svg'
      if (name.includes('EN')) image = 'gb.svg'

      if (image.length) {
        return 'https://raw.githubusercontent.com/hampusborgos/country-flags/master/svg/' + image
      }

      return ''
    },

    ping (hash) {
      fetch(`https://api.nexabridge.se/manualer/ping?hash=${hash}`)
    }
  }
}
</script>

<style>

.search {
  outline:none;
  border:0px;
  border-bottom:2px solid #d2d2d2;
  padding:0 12px 12px 0;
  width:100%;
  font-size:25px;
}

.search:focus {
}

.search::placeholder {
  color:#d2d2d2;
}

.container {
  margin:auto;
  width:50%;
}

.categories {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.categories > div {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex:1;
}

@media (max-width: 950px) {
  .categories {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }

  .categories > div {
    margin-bottom:20px;
  }
}

@media (max-width: 800px) {
  .container {
    margin:auto;
    width:90%;
  }
}

</style>
